@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat XLight';
  src: url('../public/fonts/Montserrat-ExtraLight.ttf');
}

@font-face {
  font-family: 'Montserrat Light';
  src: url('../public/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat Medium';
  src: url('../public/fonts/Montserrat-Medium.ttf');
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  overscroll-behavior: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgb(31 41 55) inset;
  -webkit-text-fill-color: white;
}

input + div[data-lastpass-icon-root] {
  display: none !important;
}

div[data-lastpass-icon-root] {
  display: none !important;
}

.HighlightActive {
  color: #4ade80;
  background-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
